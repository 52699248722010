// 遗产要素单体或局部照片
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'YCYSMC',
                label: '遗产要素'
            }, {
                prop: 'MC',
                label: '遗产要素单体'
            }, {
                prop: 'LJ',
                label: '示意图',
                showImg: true,
            }],
            // 操作列
            columnsBtn: {
                buttonData: []
            },
        },
    },
};
export default selfData;